<template>
    <div class="jodDetails">
        <div class="head f-c-b">
            <div class="LeftPart">
                <div class="f-c">
                   <div class="position">{{ detailInfo.position }}</div> 
                   <div class="ml-70 salary">{{ detailInfo.salary }}</div>
                </div>
            </div>
            <el-breadcrumb class="f-c-e" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">
                    <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
                    <span> 当前位置： 首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/CommunityIndex' }">社区</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/TalentRecruitmentIndex' }">人才招聘</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/BusinessManagementIndex' }">企业管理</el-breadcrumb-item>
                <el-breadcrumb-item>职位详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="main f-c-b">
            <div class="skill">
                <div class="skill_title">岗位详情：</div>
                <div v-html="detailInfo.postContent" class="postContent"></div>
            </div>
            <div class="enterprise">
                <el-image :src="detailInfo.enterprise ? detailInfo.enterprise.eimage1 : ''"></el-image>
                <div class="f-c-b">
                <span class="enterprise_name">{{ enterprise ? enterprise.enterpriseName : '' }}</span>
                <div class="detailsBtn" @click="toEnterprise">详情</div>
                </div>
                <div class="blueText">公司地址：</div>
                <span class="info">{{ enterprise.enterpriseName ? enterprise.enterpriseName : '未填写' }}</span>
                <div class="blueText">联系人：</div>
                <span class="info">{{ enterprise.contact ? enterprise.contact : '未填写' }}</span>
                <div class="blueText">联系方式：</div>
                <span class="info">{{ enterprise.contactTelephone ? enterprise.contactTelephone : '未填写' }}</span>
            </div>
            
        </div>
        <div class="f-c-c">
            <div class="btn" @click="tomodlify">修改职位</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'jodDetails',
    data() {
        return {
            detailInfo:{},
            enterprise:{},
        }
    },
    computed: {

    },
    created() {
        let { id } = JSON.parse(this.$UrlEncode.decode(this.$route.query.key));
        this.forumRecruitEnterpriseArticleDetails(id);
        console.log(id);
    },
    methods: {
        forumRecruitEnterpriseArticleDetails(postId){
            let that = this;
            that.$http.forumRecruitEnterpriseArticleDetails({
                postUserId: parseInt(that.$store.state.userId),
                pageSource:'2',
                postId
            }).then(res => {
                if (res.code == 200) {
                    let data = res.data;
                    console.log(data);
                    that.detailInfo = data;
                    that.enterprise = res.data.enterprise;
                    that.detailInfo.postContent = that.detailInfo.postContent.replace(/\n/g, '<br/>');
                }
            })
        },
        toEnterprise() {
            this.$setStorage('EnterpriseInternalRecruitmentInfo', this.detailInfo)
            this.$router.push('/EnterpriseInternalRecruitment')
        },
        tomodlify(){
            this.$router.push({
                name: 'ModifyJod',
                query: {
                    key: this.$UrlEncode.encode(JSON.stringify({
                        detailInfo: this.detailInfo,
                        type: 'modlify'
                    }))
                }
            })
        }

    },
}

</script>
<style lang='less' scoped>
.jodDetails {
    .head {
        padding: 20px 0;
        border-bottom: 1px solid #F7F7F8;

        .LeftPart {
            .position{
                font-weight: 500;
                font-size: 36px;
                color: #333333;
            }
            .salary{
                font-weight: 500;
                font-size: 30px;
                color: #127FF5;
            }
            .postLocation{
                font-weight: 400;
                font-size: 16px;
                color: #666666;
            }

        }

        .el-breadcrumb ::v-deep .el-breadcrumb__inner {
            color: #666666 !important;
            font-weight: 400 !important;
            font-size: 14px;
        }

        .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
            color: #0363FA !important;
            font-weight: 400 !important;
            font-size: 14px;
        }
    }
    .main {
        margin-top: 80px;

        .skill {
        width: 670px;
        height: 420px;
        background: #F8F8F8;
        border-radius: 10px;
        padding: 40px 50px;

        .skill_title {
            font-size: 18px;
            font-family: HarmonyOS Sans SC;
            font-weight: 500;
            color: #127FF5;
            margin-bottom: 20px;
        }

        .postContent {
            font-size: 16px;
            font-family: HarmonyOS Sans SC;
            font-weight: 400;
            color: #333333;
            line-height: 18px;
        }
        }

        .enterprise {
        width: 310px;
        height: 450px;
        background: #F8F8F8;
        border-radius: 10px;
        padding: 30px 45px;

        .el-image {
            width: 190px;
            height: 146px;
            border-radius: 10px;
        }

        .enterprise_name {
            margin-top: 24px;
            font-size: 24px;
            font-family: HarmonyOS Sans SC;
            font-weight: 500;
            color: #333333;
            width: 260px;
            line-height: 30px;
        }

        .detailsBtn {
            text-align: center;
            font-size: 16px;
            font-family: HarmonyOS Sans SC;
            font-weight: 400;
            color: #127FF5;
            width: 60px;
            height: 30px;
            line-height: 30px;
            background: #fff;
            border-radius: 5px;
            cursor: pointer;
        }

        .blueText {
            font-size: 18px;
            font-family: HarmonyOS Sans SC;
            font-weight: 500;
            color: #127FF5;
            line-height: 20px;
            margin-top: 25px;
            margin-bottom: 15px;
        }

        .info {
            font-size: 16px;
            font-family: HarmonyOS Sans SC;
            font-weight: 400;
            color: #333333;
            line-height: 16px;
            // margin-top: 15px;
        }
        }
        
    }
    .btn {
        margin-top: 10px;
        color: #FFFFFF;
        font-size: 16px;
        height: 60px;
        width: 160px;
        line-height: 60px;
        text-align: center;
        background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
        border-radius: 10px;
        cursor: pointer;
    }
}
</style>